export const openCommunicationApp = (type, phoneNumber) => {
    if (type === 'call') {
        // Open the phone app to make a call
        window.location.href = `tel:${phoneNumber}`;
    } else if (type === 'whatsapp') {
        // Open WhatsApp with the phone number
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    } else {
        console.error('Invalid type parameter. Use "call" or "whatsapp".');
    }
};

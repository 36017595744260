import React from 'react';
import { mainContactEmail, mainContactNumber } from '../globalExportData';

const PrivacyPolicy = () => {
  return (
    <section>
    <div className="container">
      <h1 style={{textAlign:'center', color:'gold'}}>Privacy Policy</h1>
      {/* <p><strong>Effective Date:</strong> [Date]</p> */}
      <p><strong>Liberty Tour and Travels</strong> ("we," "us," or "our") values the privacy of our users and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share information about you when you visit our website, https://libertytourandtravels.com (the "Site"), or use our services.</p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> We may collect personal information that you provide to us, such as your name, email address, phone number, and other contact details.</li>
        <li><strong>Usage Data:</strong> We may collect information about your interactions with our Site, including IP address, browser type, device information, and pages visited.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>To provide and maintain our services.</li>
        <li>To communicate with you, including sending updates, newsletters, and promotional offers.</li>
        <li>To improve our Site and services by analyzing user behavior and preferences.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer your personal information to outside parties, except as required by law or as necessary to provide our services (e.g., with payment processors or service providers).</p>

      <h2>4. Data Security</h2>
      <p>We implement security measures to protect your personal information. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.</p>

      <h2>5. Your Rights</h2>
      <p>You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at [{mainContactEmail}].</p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

      <h2>7. Contact Us</h2>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> {mainContactEmail}</li>
        <li><strong>Phone:</strong> {mainContactNumber}</li>
      </ul>
    </div>
    </section>
  );
};

export default PrivacyPolicy;

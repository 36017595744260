import React, { useEffect } from 'react';

const GoogleAds = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-CPFJ739WBG`;
    document.head.appendChild(script);

    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CPFJ739WBG');
    `;
    document.head.appendChild(inlineScript);
  }, []);

  return null;
};

export default GoogleAds;

import React, { useRef, useState } from 'react';
import CountUp from 'react-countup';
// import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './happyCustomers.css'; // Custom CSS for Happy Customers section
import useInView from '../components/useInView';

const HappyCustomers = () => {
  // const { ref } = useInView({
  //   triggerOnce: true, // Trigger animation only once
  //   threshold: 0.5 // Percentage of the element that must be visible
  // });
  const ref = useRef();

  // Define animation variants
  const countVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const inView = useInView(ref);
  return (
    <section className="happy-customers" ref={ref}>
        <div className="container">
          <div className="section-title">
            <motion.h2
              initial={{ opacity: 0, y: -50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
            >
              Happy Customers
            </motion.h2>
            <motion.i
              className="fas fa-smile"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={inView ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className="happy-customers-grid">
            <motion.div
              className="happy-customer-item"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={countVariants}
            >
              <h3>
                {inView ? <CountUp end={200} duration={2} /> : '0'}
                <span>+</span>
              </h3>
              <p>Tours</p>
            </motion.div>
            <motion.div
              className="happy-customer-item"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={countVariants}
            >
              <h3>
                {inView ? <CountUp end={500} duration={2} /> : '0'}
                <span>+</span>
              </h3>
              <p>Customers</p>
            </motion.div>
            <motion.div
              className="happy-customer-item"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={countVariants}
            >
              <h3>
                {inView ? <CountUp end={100} duration={2} /> : '0'}
                <span>+</span>
              </h3>
              <p>Vehicles</p>
            </motion.div>
            <motion.div
              className="happy-customer-item"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={countVariants}
            >
              <h3>
                {inView ? <CountUp end={50} duration={2} /> : '0'}
                <span>+</span>
              </h3>
              <p>Places</p>
            </motion.div>
          </div>
        </div>
    </section>
  );
};

export default HappyCustomers;
import React from 'react';
import { Link } from 'react-router-dom';
import { mainContactEmail, mainContactNumber, officeAddress } from '../globalExportData';
import Logo from './Logo';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content row">
          {/* Address Section */}
          <div className='footer-section address col-4'>
            <Logo
              src="/images/web_logo.png"
              alt="Liberty Tour and Travels Logo"
              width="80px"
              className="footer_logo"
            />
          </div>
          <div className="footer-section address col-4">

            <h4>Contact Us</h4>
            <p>{officeAddress}</p>
            <p>Phone: {mainContactNumber}</p>
            <p>Email: <a href={`mailto:${mainContactEmail}`}>{mainContactEmail}</a></p>
          </div>

          {/* Hot Links Section */}
          <div className="footer-section links col-4">
            <h4>Hot Links</h4>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>&copy; 2024 Liberty Tour and Travels. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

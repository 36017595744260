import React, { useRef } from 'react';
import data from '../webData.json';
import './tours.css'; // Custom CSS for Tours section
import { motion, AnimatePresence } from 'framer-motion';
import useInView from '../components/useInView';
import { cardVariants } from '../globalExportData';
import { openCommunicationApp } from '../globalFunction';

const Tours = () => {
  const ref = useRef();
  const isInView = useInView(ref);

  return (
    <section className="tours-section" ref={ref}>
      {/* {isInView && ( */}
      <div className="container">
        <div className="section-title">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Tours
          </motion.h2>
          <motion.i
            className="fas fa-globe"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          />
        </div>
        <div className="tours-grid">
          <AnimatePresence>
            {data.toursData.map((tour, index) => (
              <motion.div
                key={tour.id}
                className="tour-card"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                {/* {isInView && */}
                  <motion.img
                    src={tour.image}
                    alt={tour.place}
                    className="tour-image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.9 }}
                  />
                   {/* } */}
                <div className="tour-card-content">
                  <span className="place-name">{tour.place}</span>
                  <div className=''>
                    <button className="call-now "
                      onClick={() => openCommunicationApp('call')}>
                      Call <i className="fas fa-phone"></i>
                    </button>
                    <button className="whatsapp-now"
                      onClick={() => openCommunicationApp('whatsapp')}>
                      WhatsApp <i className="fab fa-whatsapp"></i>
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
      {/* )} */}
    </section>
  );
};

export default Tours;


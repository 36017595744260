import React, { useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Tours from './Tours';
import { motion } from 'framer-motion';
import useInView from '../components/useInView';
import HappyCustomers from './Happycustomers';
import AccordionAsk from '../components/AccordionAsk';
import OurServices from './OurServices';
import Gallery from './Gallery';
import KmServices from './KmServices';
import { openCommunicationApp } from '../globalFunction';
import { mainContactNumber } from '../globalExportData';

const Home = () => {
  const ref = useRef();
  const isInView = useInView(ref);
  return (
    <div className="home-page">

      <section className="banner-section" ref={ref}>
        {isInView && (
          <motion.div
            className="banner-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <motion.h1
              className="website-name"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 1 }}
            >
              Liberty Tour and Travels
            </motion.h1>
            <motion.p
              className="slogan"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 1 }}
            >
              "Explore the world with us"
            </motion.p>
            <motion.div
              className="contact-info"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.6, duration: 1 }}
            >
              <motion.span
                onClick={() => openCommunicationApp('whatsapp', mainContactNumber)}
                className="whatsapp-contact"
                whileHover={{ scale: 1.1 }}
              >
                <i className="fab fa-whatsapp"></i>
              </motion.span>
              <motion.span
                onClick={() => openCommunicationApp('call', mainContactNumber)}
                className="call-contact"
                whileHover={{ scale: 1.1 }}
              >
                <i className="fas fa-phone-alt"></i>
              </motion.span>
            </motion.div>
          </motion.div>
        )}
      </section>

      {/* Carousel Section */}
      {/* <section className='carousel-css'>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/crousel_img/c_1.jpg"
              alt="Slide 1"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/crousel_img/c_2.jpg"
              alt="Slide 2"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/crousel_img/c_3.jpg"
              alt="Slide 3"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="/images/crousel_img/c_4.jpg"
              alt="Slide 2"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 img5"
              src="/images/crousel_img/c_5.jpg"
              alt="Slide 3"
            />
          </Carousel.Item>
        </Carousel>
      </section> */}

      <section className="about-us">
        <div className="container">
          <div className="section-title">
            <h2>About Liberty Tour and Travels</h2>
            <i className="fas fa-info-circle"></i>
          </div>
          <p>
            Welcome to Liberty Tour and Travels, your gateway to unforgettable journeys and experiences.
            As a trusted leader in the travel industry, we are dedicated to making your travel dreams a reality.
          </p>
          <p>
            At Liberty Tour and Travels, we believe that every journey is an opportunity to discover,
            connect, and grow. Whether you're yearning for the tranquility of serene landscapes, the thrill of
            adventurous expeditions, or the vibrant pulse of bustling cities, we have curated the perfect travel
            packages to meet your needs.
          </p>
          <p>
            Our team of travel experts works tirelessly to ensure every detail of your trip is seamless and
            memorable. From handpicked accommodations to tailor-made itineraries, we take care of everything,
            so you can focus on enjoying the experience. Let Liberty Tour and Travels be your trusted companion
            in exploring the wonders of the world.
          </p>
        </div>
      </section>

      {/* km services */}
      <KmServices />
      {/* Tours Section */}
      <Tours />

      {/* Happy Customers Section */}
      <HappyCustomers />

      {/* Our Services Section */}
      <OurServices />

      {/* frq ask question  */}
      <AccordionAsk />

      {/* Gallery Section */}
      {/* <section className="gallery">
        <div className="container">
          <div className="section-title">
            <h2>Gallery</h2>
            <i className="fas fa-images"></i>
          </div>
          <div className="gallery-grid">
            <div className="gallery-item"><img src="gallery1.jpg" alt="Gallery Item 1" /></div>
            <div className="gallery-item"><img src="gallery2.jpg" alt="Gallery Item 2" /></div>
            <div className="gallery-item"><img src="gallery3.jpg" alt="Gallery Item 3" /></div>
          </div>
        </div>
      </section> */}
      <Gallery />

    </div>
  );
};

export default Home;

export const cardVariants = {
  fadeIn: {
    opacity: 1,
    transition: { duration: 0.5 }
  },
  slideIn: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 }
  },
  zoomIn: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.5 }
  },
  rotateIn: {
    opacity: 1,
    rotate: 360,
    transition: { duration: 0.5 }
  }
};

export const mainContactNumber = '+919669588862'
export const mainContactEmail = 'tour@libertytourandtravels.com'
export const officeAddress = '102/260, Deora house Alok nagar kanadiya road indore madhya 452016'
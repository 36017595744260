import React, { useState } from 'react';
import './contact.css';
import { mainContactEmail, mainContactNumber } from '../globalExportData';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setFormData({ name: '', email: '', message: '' });
    return
    emailjs.send(
      'your_service_id', // Replace with your Service ID
      'template_rqhkbnc', // Replace with your Template ID
      formData,
      'your_user_id' // Replace with your User ID
    ).then((response) => {
      setIsSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    }).catch((error) => {
      alert('Failed to send the message, please try again.');
    });
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <p>Get in touch with us for the best deals.</p>
      <div className="contact-details">
        <h2>Get in Touch</h2>
        <p><strong>Phone:</strong> <a href={`tel:${mainContactNumber}`}>{mainContactNumber}</a></p>
        <p><strong>Email:</strong> <a href={`mailto:${mainContactEmail}`}>{mainContactEmail}</a></p>
      </div>
      {isSubmitted ? (
        <div className="thank-you-message">
          <i className="fas fa-check-circle thank-you-icon"></i>
          <h2>Thank You!</h2>
          <p>Your message has been sent successfully. We will get back to you soon.</p>
        </div>
      ) : (
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Your Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Your Message:</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Contact;

import React from 'react';
import data from '../webData.json';
import './ourServices.css'
import { openCommunicationApp } from '../globalFunction';
import { mainContactNumber } from '../globalExportData';

const OurServices = () => {
    return (
        <section className="our-services">
            <div className="container">
                <div className="section-title">
                    <h2>Our Services</h2>
                    <i className="fas fa-concierge-bell"></i>
                </div>
                <div className="services-grid">
                    {data.services.map(service => (
                        <div key={service.id} className="service-card">
                            <img src={service.image} alt={service.name} className="service-image" />
                            <h3 className="service-name">{service.name}</h3>
                            <p className="service-details">{service.details}</p>
                            <button
                                className="enquiry-button"
                                onClick={() => openCommunicationApp('call', mainContactNumber)}>
                                Enquiry Now
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default OurServices;

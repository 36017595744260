import React, { useRef } from 'react';
import './kmservices.css'; // Ensure to create and style services.css as required
import data from '../webData.json'; // Import the services data from your JSON file
import useInView from '../components/useInView';
import { motion } from 'framer-motion';
import { openCommunicationApp } from '../globalFunction';
import { mainContactNumber } from '../globalExportData';

const KmServices = () => {
  const ref = useRef();
  const inView = useInView(ref);

  return (
    <section className="services-section" ref={ref}>
      <div className="container">
        <div className="section-title text-center mb-4">
          <h2>Services</h2>
          <i className="fas fa-concierge-bell"></i>
        </div>
        <div className="row service-div">
            <div className="col-sm-6">
              <img src="/images/service.jpg" alt="img" className="img-fluid service-img mb-3 w-100" />
            </div>
            <div className="col-sm-6 d-flex justify-content-center">
              <ul className="service-text list-unstyled">
                <h2>Best that we provide you:</h2>
                {data.ourServices.map(service => (
                  <li key={service.id}>- {service.name} -</li>
                ))}
              </ul>
            </div>
          </div>
        <div className="row g-3">
          {data.kmServices.map((service) => (
            <div key={service.id} className="col-12 col-md-6" >
              {/* {inView && ( */}
              <motion.div
                onClick={() => openCommunicationApp('call', mainContactNumber)}
                className="service-card-km text-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.9 }}
              >
                <img src={service.image} alt={service.name} className="img-fluid rounded mb-3" />
                <h3 className="service-name">{service.name}</h3>
                <p className="service-seat">
                  <i className="fas fa-users"></i> {service.seat}
                </p>
                <motion.div
                  className="book-now"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  <span className="line"></span>
                  <span className="text">BOOK NOW</span>
                  <span className="line"></span>
                </motion.div>
              </motion.div>
              {/* )} */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KmServices;

import React from 'react';
import { mainContactEmail, mainContactNumber } from '../globalExportData';

const TermsAndConditions = () => {
  return (
    <section>
      <div className="container">
        <h1 style={{ textAlign: 'center', color: 'gold' }}>Terms and Conditions</h1>
        {/* <p><strong>Effective Date:</strong> [Date]</p> */}
        <p>Welcome to <strong>Liberty Tour and Travels</strong>. By using our website, https://libertytourandtravels.com & https://libertytourandtravels.in (the "Site"), you agree to comply with and be bound by the following terms and conditions (the "Terms"). Please read these Terms carefully before using our Site.</p>

        <h2>1. Acceptance of Terms</h2>
        <p>By accessing or using our Site, you agree to these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our Site.</p>

        <h2>2. Use of the Site</h2>
        <p>You agree to use the Site only for lawful purposes and in accordance with these Terms. You may not use the Site:</p>
        <ul>
          <li>In any way that violates any applicable local, state, national, or international law or regulation.</li>
          <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent.</li>
        </ul>

        <h2>3. Intellectual Property</h2>
        <p>The content, features, and functionality on the Site are owned by Liberty Tour and Travels and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

        <h2>4. Limitation of Liability</h2>
        <p>In no event shall Liberty Tour and Travels, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:</p>
        <ul>
          <li>Your use or inability to use the Site;</li>
          <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
          <li>Any interruption or cessation of transmission to or from the Site;</li>
          <li>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party;</li>
        </ul>

        <h2>5. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time, and such modifications will be effective immediately upon posting on the Site. Your continued use of the Site after any such modifications constitutes your acceptance of the new Terms.</p>

        <h2>6. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of Indore, without regard to its conflict of law provisions.</p>

        <h2>7. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at:</p>
        <ul>
          <li><strong>Email:</strong> {mainContactEmail}</li>
          <li><strong>Phone:</strong> {mainContactNumber}</li>
        </ul>
      </div>
    </section>
  );
};

export default TermsAndConditions;

import React, { useRef, useState } from 'react';
import './gallery.css'; // Ensure to create and style Gallery.css as required
import data from '../webData.json'; // Import the tours data from your JSON file
import useInView from '../components/useInView';
import { motion } from 'framer-motion';

const Gallery = () => {
  const ref = useRef();
  const inView = useInView(ref);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <section className="gallery" ref={ref}>
      <div className="container">
        <div className="section-title text-center mb-4">
          <h2>Gallery</h2>
          <i className="fas fa-images"></i>
        </div>
        <div className="gallery-grid row g-3">
          {data.toursData.map((tour) => (
            <div key={tour.id} className="gallery-item col-12 col-md-6 col-lg-3">
              {/* {inView && ( */}
                <motion.img
                  src={tour.image}
                  alt={`Gallery Item ${tour.place}`}
                  className="img-fluid rounded"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.9 }}
                  onClick={() => handleImageClick(tour.image)}
                  data-bs-toggle="modal"
                  data-bs-target="#imageModal"
                />
              {/* )} */}
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="imageModalLabel">Image Preview</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {selectedImage && <img src={selectedImage} alt="Selected" className="img-fluid" />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;

import React from 'react';
import './aboutus.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Welcome to Liberty Tour and Travels, your ultimate travel partner! We are dedicated to creating unforgettable travel experiences that are tailored to your needs. Our agency specializes in providing a wide range of travel services, including car rentals, bus services, and hotel bookings, ensuring a seamless journey from start to finish.
      </p>
      <p>
        At Liberty Tour and Travels, we believe that the journey is just as important as the destination. Whether you're planning a family vacation, a corporate trip, or a solo adventure, our team of experienced travel experts is here to assist you in making your travel dreams come true. We offer carefully curated packages that showcase the best of every destination, with a focus on comfort, safety, and enjoyment.
      </p>
      <p>
        Our commitment to excellence is reflected in our diverse range of services. From luxurious hotel stays to comfortable and reliable transportation options, we ensure that every aspect of your journey is handled with the utmost care and attention to detail. Our fleet of well-maintained vehicles and partnerships with top-rated hotels guarantee that you will travel in style and stay in comfort.
      </p>
      <p>
        As a company, Liberty Tour and Travels is built on the principles of trust, reliability, and customer satisfaction. We take pride in our ability to offer personalized services that cater to your unique preferences and budget. Our goal is to make travel accessible and enjoyable for everyone, and we are committed to providing the highest quality service to all our clients.
      </p>
      <p>
        Join us at Liberty Tour and Travels and embark on a journey of a lifetime. Let us take care of the logistics while you focus on creating beautiful memories. Your adventure awaits!
      </p>
    </div>
  );
};

export default About;

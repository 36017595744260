import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ src, alt, width, height, className}) => {
  return (
    <div className="logo">
      <img src={src} alt={alt} width={width} height={height} className={className}/>
    </div>
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,    // The source of the logo image
  alt: PropTypes.string.isRequired,    // Alt text for the image
  width: PropTypes.string,             // Width of the logo (optional)
  height: PropTypes.string,            // Height of the logo (optional)
};

Logo.defaultProps = {
  width: '100px',                      // Default width
  height: 'auto',                      // Default height
};

export default Logo;

import React from 'react';
import { openCommunicationApp } from '../globalFunction';
import { mainContactNumber } from '../globalExportData';

const StickyIcons = () => {
  return (
    <div className="position-fixed bottom-0 mb-5 end-0 p-3" style={{ zIndex: 1000 }}>
      <div className="d-flex flex-column gap-2">
        <button
          className="btn btn-success rounded-circle"
          onClick={() => openCommunicationApp('whatsapp', mainContactNumber)}
          aria-label="WhatsApp"
          style={{ width: '50px', height: '50px' }}
        >
          <i className="fab fa-whatsapp"></i>
        </button>
        <button
          className="btn btn-primary rounded-circle"
          onClick={() => openCommunicationApp('call', mainContactNumber)}
          aria-label="Call"
          style={{ width: '50px', height: '50px' }}
        >
          <i className="fas fa-phone-alt"></i>
        </button>
      </div>
    </div>
  );
};

export default StickyIcons;

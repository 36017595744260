import React from 'react';
import { mainContactEmail, mainContactNumber } from '../globalExportData';

const AccordionAsk = () => {
    return (
        <section className="our-services">
            <div className="container">
                <div className="section-title">
                    <h2>FREQUENTLY ASKED QUESTIONS</h2>
                    <i className="fas fa-concierge-bell"></i> {/* Icon kept the same */}
                </div>
                {/* <div className="services-grid"> */}
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What are the various modes of payment accepted here?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We accept multiple modes of payment for your convenience, including:
                                    <ul>
                                        <li>Cash</li>
                                        <li>Cash on Delivery</li>
                                        <li>Card on Delivery</li>
                                        <li>Cheque</li>
                                        <li>UPI</li>
                                        <li>Airtel Money</li>
                                        <li>G Pay</li>
                                        <li>NEFT</li>
                                        <li>RTGS</li>
                                        <li>Traveller's Cheque</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    What are your hours of operation?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    We are open 24 hours a day, 7 days a week:
                                    <ul>
                                        <li>Monday: Open 24 Hrs</li>
                                        <li>Tuesday: Open 24 Hrs</li>
                                        <li>Wednesday: Open 24 Hrs</li>
                                        <li>Thursday: Open 24 Hrs</li>
                                        <li>Friday: Open 24 Hrs</li>
                                        <li>Saturday: Open 24 Hrs</li>
                                        <li>Sunday: Open 24 Hrs</li>
                                    </ul>
                                    You can reach out to us anytime, as we are operational round the clock.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What is your contact number?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    You can reach us directly for bookings or inquiries at: <strong>{mainContactNumber}</strong> or <strong>{mainContactEmail}</strong>. We're here to assist you with all your needs.
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </section>
    );
};

export default AccordionAsk;
